<template>
  <b-form-group
    label="Fatura Numarası"
    label-for="invoice_number"
  >
    <b-form-input
      id="invoice_number"
      v-model="dataItem.invoice_number"
      placeholder="Fatura Numarası"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'InvoiceNumberCard',
  components: { BFormInput, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
