<template>
  <div>
    <b-form-group
      label="Birim Fiyat"
      label-for="price"
    >
      <validation-provider
        #default="{ errors }"
        name="Birim Fiyat"
        rules="required"
      >
        <b-input-group append="TL">
          <cleave
            id="price"
            v-model="dataItem.price"
            class="form-control"
            :raw="true"
            :options="options"
            placeholder="Giriniz"
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup, BInputGroup,
} from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PriceCard',
  components: {
    BInputGroup,
    ValidationProvider,
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
  },
  created() {
    localize('tr')
  },

}
</script>
