<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="InboxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stockData.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Stok Kartı
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="CheckCircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stockData.amount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Toplam {{ stockData.units }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="DownloadIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stockData.entry_amounts | toNumber }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Toplam Giriş {{ stockData.units }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="UploadIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ stockData.output_amounts | toNumber }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Toplam Çıkış {{ stockData.units }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      pills
      fill
    >
      <b-tab
        active
        :disabled="!entry"
      >
        <template #title>
          <feather-icon icon="DownloadIcon" />
          <span>Giriş Hareketi</span>
        </template>
        <validation-observer ref="simpleRulesEntry">
          <b-card title="Giriş Hareketi Ekle">
            <entry-stock-line-form />
          </b-card>
          <div class="text-left">
            <b-button
              variant="primary"
              class="text-left"
              :disabled="dataItem.submitStatus"
              @click="EntrySubmitData"
            >
              Kaydet
            </b-button>
          </div>
        </validation-observer>
      </b-tab>
      <b-tab
        lazy
        :disabled="!output"
      >
        <template #title>
          <feather-icon icon="UploadIcon" />
          <span>Çıkış Hareketi</span>
        </template>
        <validation-observer ref="simpleRulesOut">
          <b-card title="Çıkış Hareketi Ekle">
            <output-stock-line-form />
          </b-card>
          <div class="text-left">
            <b-button
              variant="primary"
              class="text-left"
              :disabled="dataItem.submitStatus"
              @click="OutSubmitData"
            >
              Kaydet
            </b-button>
          </div>
        </validation-observer>
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver } from 'vee-validate'
import EntryStockLineForm from '@/views/Admin/Stocks/Components/entryStockLineForm.vue'
import {
  BAvatar,
  BButton, BCard, BCardText, BCol, BMedia, BMediaAside, BMediaBody, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import OutputStockLineForm from '@/views/Admin/Stocks/Components/OutputStockLineForm.vue'

export default {
  name: 'StocksAddLines',
  components: {
    BAvatar,
    BRow,
    BMediaAside,
    BCardText,
    BCol,
    BMediaBody,
    BMedia,
    BButton,
    OutputStockLineForm,
    EntryStockLineForm,
    ValidationObserver,
    BCard,
    BTab,
    BTabs,
  },
  data() {
    return {
      output: true,
      entry: true,
    }
  },
  computed: {
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    saveData() {
      return this.$store.getters['stockLines/dataSaveStatus']
    },

  },
  watch: {
    dataItem: {
      deep: true,
      handler(val) {
        if (val.entry_amount) {
          this.output = false
        } else {
          this.output = true
        }
        if (val.output_amount) {
          this.entry = false
        } else {
          this.entry = true
        }
      },
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push(`/app/stocks/view/${this.$route.params.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getStockData()
    this.$store.commit('stockLines/RESET_DATA_ITEM')
  },
  methods: {
    EntrySubmitData() {
      this.$refs.simpleRulesEntry.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_stocks = this.$route.params.id
          this.$store.dispatch('stockLines/saveData', this.dataItem)
        } else {
          this.showToast({
            variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Tüm alanları doldurmalısınız.',
          })
        }
      })
    },
    OutSubmitData() {
      this.$refs.simpleRulesOut.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.id_stocks = this.$route.params.id
          this.$store.dispatch('stockLines/saveData', this.dataItem)
        } else {
          this.showToast({
            variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: 'Tüm alanları doldurmalısınız.',
          })
        }
      })
    },
    getStockData() {
      this.$store.dispatch('stocks/getDataItem', this.$route.params.id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
