<template>
  <div>
    <b-row>
      <b-col>
        <invoice-number-card />
      </b-col>
      <b-col>
        <output-amount-card />
      </b-col>
      <b-col>
        <price-card />
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <suppliers-card />
      </b-col>
      <b-col>
        <projects-card />
      </b-col>
      <b-col>
        <warehouses-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <notes-card />
      </b-col>
    </b-row>

  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import PriceCard from '@/views/Admin/Stocks/Elements/AddLine/priceCard.vue'
import InvoiceNumberCard from '@/views/Admin/Stocks/Elements/AddLine/invoiceCard.vue'
import OutputAmountCard from '@/views/Admin/Stocks/Elements/AddLine/output_amount.vue'
import SuppliersCard from '@/views/Admin/Stocks/Elements/AddLine/suppliersCard.vue'
import WarehousesCard from '@/views/Admin/Stocks/Elements/AddLine/wareHousesCard.vue'
import NotesCard from '@/views/Admin/Stocks/Elements/AddLine/Notes.vue'
import ProjectsCard from '@/views/Admin/Stocks/Elements/AddLine/ProjectsCard.vue'

export default {
  name: 'OutputStockLineForm',
  components: {
    ProjectsCard,
    NotesCard,
    WarehousesCard,
    SuppliersCard,
    OutputAmountCard,
    InvoiceNumberCard,
    PriceCard,

    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
  },
}
</script>
