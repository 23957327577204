<template>
  <b-form-group
    label="İşlem Açıklaması"
    label-for="notes"
  >
    <validation-provider
      #default="{ errors }"
      name="İşlem Açıklaması"
      rules="required"
    >
      <b-form-textarea
        id="notes"
        v-model="dataItem.notes"
        placeholder="Not"
      />
      <small class="text-danger">{{ errors[0] }}</small>

    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'NotesCard',
  components: { ValidationProvider, BFormTextarea, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
