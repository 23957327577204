<template>
  <div>
    <b-form-group
      label="Proje"
      label-for="id_projects"
    >
      <v-select
        id="id_projects"
        v-model="dataItem.id_projects"
        :options="projects"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div>
            {{ option.title }}
            <div v-if="option.pnumber">
              <small class="text-warning">{{ option.pnumber }}</small>
            </div>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.title }}
          </div>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { localize } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'ProjectsCard',
  components: {
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    projects() {
      return this.$store.getters['projects/dataList']
    },
  },
  created() {
    localize('tr')
    this.getSuppliers()
  },
  methods: {
    getSuppliers() {
      this.$store.dispatch('projects/getDataList', {
        select: [
          'projects.id as id',
          'projects.title as title',
          'CONCAT(customers.cnumber,"-P",projects.pnumber) AS pnumber',
        ],
        where: {
          'projects.id_project_statuses': 11,
        },
      })
    },
  },
}
</script>
