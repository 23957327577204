<template>
  <b-form-group
    label="Çıkış Miktarı"
    label-for="output_amount"
  >
    <validation-provider
      #default="{ errors }"
      name="Çıkış Miktarı"
      rules="required"
    >
      <b-input-group :append="stockData.units">
        <cleave
          id="output_amount"
          v-model="dataItem.output_amount"
          class="form-control"
          :raw="true"
          :options="options"
          placeholder="Giriniz"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup, BInputGroup } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'OutputAmountCard',
  components: {
    ValidationProvider, BFormGroup, BInputGroup, Cleave,
  },
  data() {
    return {
      required,
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['stockLines/dataItem']
    },
    stockData() {
      return this.$store.getters['stocks/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
